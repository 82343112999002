import { httpApi } from "./httpApi.js";
import { handleError } from "@/lib/helpers";
import { formatDataToChoicesJs } from "@/assets/js/init-choices";

class API {
  async login(data) {
    await httpApi.get("/sanctum/csrf-cookie");
    return await httpApi.post("/api/admin/login", data);
  }

  async logout() {
    return await httpApi.post("/api/admin/logout");
  }

  async getUserList() {
    return await httpApi.get("/api/admin/user");
  }

  async getUser(id) {
    return await httpApi.get("/api/admin/user/" + id);
  }

  async createUser(data) {
    return await httpApi.post("/api/admin/user", data);
  }

  async updateUser(usrId, data) {
    return await httpApi.put("/api/admin/user/" + usrId, data);
  }

  async getRoleList() {
    return await httpApi.get("/api/admin/role");
  }

  async getRoleById(id) {
    return await httpApi.get("/api/admin/role/" + id);
  }

  async createRole(data) {
    return await httpApi.post("/api/admin/role", data);
  }

  async getPermissionList() {
    return await httpApi.get("/api/admin/permission");
  }

  async GetPermissionById(id) {
    return await httpApi.get("/api/admin/permission/" + id);
  }

  async updatePermission(id, data) {
    return await httpApi.put("/api/admin/permission/" + id, data);
  }

  async deletePermission(id) {
    return await httpApi.delete("/api/admin/permission/" + id);
  }

  async createPermission(data) {
    return await httpApi.post("/api/admin/permission", data);
  }

  async addRolePermission(id, data) {
    return await httpApi.post("/api/admin/role/" + id + "/permissions", data);
  }

  async updateRole(id, data) {
    return await httpApi.put("/api/admin/role/" + id, data);
  }

  async getProteinsList() {
    return await httpApi.get("/api/admin/protein-category");
  }
  async getCompanyList() {
    return await httpApi.get("/api/admin/company");
  }

  async getCompanyById(id) {
    return await httpApi.get("/api/admin/company/" + id);
  }

  async createClientsRemarks(id, data) {
    return await httpApi.post("/api/admin/company/" + id + "/add-notes", data);
  }

  async updateCompanyAddress(id, data) {
    return await httpApi.put("/api/admin/company/" + id + "/add-address", data);
  }

  async updateCompanyDetails(id, data) {
    return await httpApi.post("/api/admin/company/" + id, data);
  }

  async getCompanyaddress(id) {
    return await httpApi.get("/api/admin/getCompanyaddress/", +id);
  }

  async addCompanyDocument(id, data) {
    return await httpApi.post(
      "/api/admin/company/" + id + "/add-document",
      data
    );
  }

  async inviteEmployee(id, data) {
    return await httpApi.post(
      "/api/admin/company/" + id + "/invite-employees",
      data
    );
  }

  async updateCompanyDocument(id, documentId, data) {
    return await httpApi.post(
      "/api/admin/company/" + id + "/edit-document/" + documentId,
      data
    );
  }

  async approveCompany(id) {
    return await httpApi.put("/api/admin/company/" + id + "/approve");
  }

  async rejectCompany(id) {
    return await httpApi.put("/api/admin/company/" + id + "/reject");
  }

  async getAllergensList() {
    return await httpApi.get("/api/admin/allergen");
  }

  async getAllergensById(id) {
    return await httpApi.get("/api/admin/allergen/" + id);
  }

  async createAllergens(data) {
    return await httpApi.post("/api/admin/allergen", data);
  }

  async updateAllergens(id, data) {
    return await httpApi.put("/api/admin/allergen/" + id, data);
  }

  async getCurrentProfile() {
    return await httpApi.get("/api/admin/profile");
  }

  async getAllergens(params) {
    return await httpApi.get("/api/admin/allergen?" + params);
  }

  async getFeatures(params) {
    return await httpApi.get("/api/admin/features?" + params);
  }

  async getProteinCategory(params) {
    return await httpApi.get("/api/admin/protein-category?" + params);
  }

  async getClientsList(params) {
    if (!params) {
      params = "start=0&length=10";
    }
    return await httpApi.get("/api/admin/client?" + params);
  }

  async getClientbyId(id) {
    return await httpApi.get("/api/admin/client/" + id);
  }

  async getOrderListbyIdClientId(id) {
    return await httpApi.get("/api/admin/order?filter[client_id]=" + id);
  }

  async getDeliveryListClientId(id) {
    return await httpApi.get(
      "/api/admin/order-deliveries?filter[client_id]=" + id
    );
  }

  async updateClient(id, data) {
    return await httpApi.put("/api/admin/client/" + id, data);
  }

  async deleteClientById(id) {
    return await httpApi.delete("/api/admin/client/" + id);
  }

  async getinvoiceList() {
    return await httpApi.get("/api/admin/invoice");
  }

  async getinvoiceById(id) {
    return await httpApi.get("/api/admin/invoice/" + id);
  }

  formatUserDataForEditUser(data) {
    let roles = [];
    for (let i in data.roles.data) {
      roles.push(data.roles.data[i].id);
    }
    return {
      name: data.name,
      email: data.email,
      username: data.username,
      mobile_number: data.mobile_number,
      bio: data.bio,
      image: data.image,
      roles: roles,
    };
  }

  formatDataToChoicesJs(data, empty_option, fields) {
    return formatDataToChoicesJs(data, empty_option, fields);
  }
  handleError(err) {
    return handleError(err);
  }
  formatUserPermissionsToCaslAbilities(data) {
    let result = [];
    for (let i in data) {
      let t = data[i].name.split(".");
      result.push({
        action: t[1],
        subject: t[0],
      });
    }
    return result;
  }
}

export default new API();
