<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <template v-if="menuItems.length > 0">
        <li v-for="(menuItem, key) in menuItems" :key="key" class="nav-item">
          <template v-if="menuItem.subMenuItems">
            <sidenav-collapse
              v-if="showMenuItem(menuItem)"
              collapse-ref="pagesExamples"
              :nav-text="menuItem.navText"
              :class="menuItem.class"
            >
              <template #icon>
                <i class="text-sm" :class="menuItem.iconClass"></i>
              </template>
              <template #list>
                <ul class="nav ms-4">
                  <!-- nav links -->
                  <template
                    v-for="(
                      subMenuItem, subMenuItemKey
                    ) in menuItem.subMenuItems"
                    :key="subMenuItemKey"
                  >
                    <template v-if="-showSubMenuItem(subMenuItem)">
                      <sidenav-item
                        v-if="subMenuItem.roleId"
                        :to="subMenuItem.to"
                        :mini-icon="subMenuItem.miniIcon"
                        :text="subMenuItem.text"
                        :text-class="menuItem.textClass"
                        @click="
                          $store.commit(
                            'auth/setCurrentRole',
                            subMenuItem.roleId
                          )
                        "
                      />
                      <sidenav-item
                        v-else
                        :to="subMenuItem.to"
                        :mini-icon="subMenuItem.miniIcon"
                        :text="subMenuItem.text"
                        :text-class="menuItem.textClass"
                      />
                    </template>
                  </template>
                </ul>
              </template>
            </sidenav-collapse>
          </template>
          <template v-else>
            <sidenav
              v-if="showMenuItem(menuItem)"
              :text-class="menuItem.textClass"
              :nav-text="menuItem.navText"
              :to="menuItem.to"
            >
              <template #icon>
                <i class="text-sm" :class="menuItem.iconClass"></i>
              </template>
            </sidenav>
          </template>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import Sidenav from "./Sidenav.vue";
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import ability from "@/services/ability";

export default {
  name: "SidenavList",
  components: {
    Sidenav,
    SidenavItem,
    SidenavCollapse,
  },
  data() {
    return {
      menuItems: [],
    };
  },
  mounted() {
    this.menuItems = this.getMenuItems();
    this.setMenuItemsGuard();
  },
  methods: {
    getMenuItems() {
      const defaultIconClass =
        this.$store.state.sidebarType === "bg-default" ||
        this.$store.state.darkMode
          ? "text-white"
          : "text-dark";
      let menuItems = [
        {
          navText: "Dashboard",
          class: this.getRoute() === "dashboard" ? "active" : "",
          iconClass: "fa fa-home " + defaultIconClass,
          to: { name: "Dashboard" },
        },
        {
          navText: "Orders",
          class: this.getRoute() === "orders" ? "active" : "",
          iconClass: "fa fa-file-invoice " + defaultIconClass,
          to: { name: "Orders List" },
        },

        {
          navText: "Clients",
          auth: true,
          class: this.getRoute() === "clients" ? "active" : "",
          iconClass: "ni ni-single-02 " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Companies List" },
              miniIcon: "CO",
              text: "Companies",
            },
            {
              to: { name: "Client List" },
              miniIcon: "C",
              text: "Clients",
            },
            {
              to: { name: "Invoices List" },
              miniIcon: "I",
              text: "Invoices",
            },
            {
              to: { name: "Refund Requests" },
              miniIcon: "R",
              text: "Refund Requests",
            },
          ],
        },
        {
          navText: "Users",
          auth: true,
          class: this.getRoute() === "users" ? "active" : "",
          iconClass: "ni ni-single-02 " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Users List" },
              miniIcon: "U",
              text: "Users",
            },
            {
              to: { name: "User Roles List" },
              miniIcon: "R",
              text: "Roles",
            },
            {
              to: { name: "Permissions List" },
              miniIcon: "R",
              text: "Permissions",
            },
          ],
        },
        {
          navText: "Config",
          auth: true,
          // guard: {
          //   action: "view",
          //   subject: "configs",
          // },
          class: this.getRoute() === "config" ? "active" : "",
          iconClass: "ni ni-settings opacity-10 " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Recipes" },
              miniIcon: "R",
              text: "Recipes",
            },
            {
              to: { name: "Recipe Types" },
              miniIcon: "RT",
              text: "Recipe Types",
            },
            {
              to: { name: "Proteins" },
              miniIcon: "P",
              text: "Proteins",
            },
            {
              to: { name: "Allergens" },
              miniIcon: "A",
              text: "Allergens",
            },
          ],
        },
        {
          navText: "Logistics",
          auth: true,
          // guard: {
          //   action: "view",
          //   subject: "logistics",
          // },
          class: this.getRoute() === "logistics" ? "active" : "",
          iconClass: "ni ni-delivery-fast " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Bag Movements" },
              miniIcon: "B",
              text: "Bag Movements",
            },
            {
              to: { name: "Bag Movement Check-out" },
              miniIcon: "O",
              text: "Bag Movement Check-out",
            },
            {
              to: { name: "Bag Movement Check-in" },
              miniIcon: "I",
              text: "Bag Movement Check-in",
            },

            {
              to: { name: "Deliveries" },
              miniIcon: "D",
              text: "Deliveries",
            },
          ],
        },
        {
          navText: "Operations",
          auth: true,
          // guard: {
          //   action: "view",
          //   subject: "operations",
          // },
          class: this.getRoute() === "operations" ? "active" : "",
          iconClass: "ni ni-bullet-list-67 " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "TDO" },
              miniIcon: "T",
              text: "TDO",
            },
            {
              to: { name: "Packing List" },
              miniIcon: "P",
              text: "Packing List",
            },
            {
              to: { name: "Delivery Schedule" },
              miniIcon: "D",
              text: "Delivery Schedule",
            },
            {
              to: { name: "Delivery Summary" },
              miniIcon: "DS",
              text: "Delivery Summary",
            },
            {
              to: { name: "Delivery Notes" },
              miniIcon: "DN",
              text: "Delivery Notes",
            },
            {
              to: { name: "Labels" },
              miniIcon: "L",
              text: "Labels",
            },
            {
              to: { name: "Food Label" },
              miniIcon: "F",
              text: "Food Label",
            },
          ],
        },
        {
          navText: "Reports",
          auth: true,
          // guard: {
          //   action: "view",
          //   subject: "reports",
          // },
          class: this.getRoute() === "reports" ? "active" : "",
          iconClass: "ni ni-collection " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Item Wise Consolidated Report" },
              miniIcon: "ICR",
              text: "Item Wise Report",
            },
            {
              to: { name: "Reconciliation Report" },
              miniIcon: "RR",
              text: "Reconciliation Report",
            },
          ],
        },
      ];
      menuItems = this.applyRouterMetaToMenuItems(menuItems);
      return menuItems;
    },
    applyRouterMetaToMenuItems(menuItems) {
      return menuItems.map((item) => {
        if (item.to && item.to.name) {
          const route = this.$router.resolve({ name: item.to.name });
          if (route.meta) {
            item.auth = route.meta.auth;
            item.guard = route.meta.guard;
          }
        }
        if (item.subMenuItems) {
          item.subMenuItems = this.applyRouterMetaToMenuItems(
            item.subMenuItems
          );
        }
        return item;
      });
    },
    setMenuItemsGuard() {
      for (let i in this.menuItems) {
        if (this.menuItems[i].auth) {
          if (this.menuItems[i].subMenuItems) {
            for (let j in this.menuItems[i].subMenuItems) {
              let route = this.$router.resolve({
                name: this.menuItems[i].subMenuItems[j].to.name,
              });
              if (route.meta && route.meta.guard) {
                this.menuItems[i].subMenuItems[j].guard = route.meta.guard;
              }
            }
          } else {
            let route = this.$router.resolve({
              name: this.menuItems[i].to.name,
            });
            if (route.meta && route.meta.guard) {
              this.menuItems[i].guard = route.meta.guard;
            }
          }
        }
      }
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    getDashboardSubMenuItemsBasedOnRole() {
      let result = [];
      if (!this.$store.state.auth.user.roles) {
        return result;
      }
      let route = "";
      for (let i in this.$store.state.auth.user.roles.data) {
        if (this.$store.state.auth.user.roles.data[i].id == 1) {
          route = "/dashboard";
        }
        if (this.$store.state.auth.user.roles.data[i].id == 2) {
          route = "/dashboard-account-admin";
        }
        if (this.$store.state.auth.user.roles.data[i].id == 3) {
          route = "/dashboard-account-manager";
        }
        if (this.$store.state.auth.user.roles.data[i].id == 4) {
          route = "/dashboard-account-supervisor";
        }
        if (this.$store.state.auth.user.roles.data[i].id == 5) {
          route = "/dashboard-nutritionist";
        }
        if (this.$store.state.auth.user.roles.data[i].id == 8) {
          route = "/dashboard-sales-agent";
        }

        if (route) {
          result.push({
            to: route,
            miniIcon: this.$store.state.auth.user.roles.data[i].name.charAt(0),
            text: this.$store.state.auth.user.roles.data[i].name,
            roleId: this.$store.state.auth.user.roles.data[i].id,
          });
        }
        route = "";
      }
      if (result.length == 0) {
        result.push({
          to: "/dashboard-default",
          miniIcon: "D",
          text: "Default",
          roleId: "",
        });
      }
      return result;
    },
    showMenuItem(menuItem) {
      if (menuItem.auth) {
        if (!this.$store.state.auth.authenticated) {
          return false;
        }
        if (
          menuItem.guard &&
          !ability.can(menuItem.guard.action, menuItem.guard.subject)
        ) {
          return false;
        }
        if (menuItem.subMenuItems) {
          return menuItem.subMenuItems.some((subMenuItem) =>
            this.showSubMenuItem(subMenuItem)
          );
        }
      }
      return true;
    },
    showSubMenuItem(subMenuItem) {
      if (subMenuItem.auth) {
        if (!this.$store.state.auth.authenticated) {
          return false;
        }
        return (
          !subMenuItem.guard ||
          ability.can(subMenuItem.guard.action, subMenuItem.guard.subject)
        );
      }
      return true;
    },
  },
};
</script>
